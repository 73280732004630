<template>
  <div class="wrapper">
    <div
      class="signup inner-wrapper"
      :class="{
        'not-show-border': displayedTerms || displayedPrivacyPolicy
      }"
    >
      <div
        class="signup-form accounts-form"
        v-show="!displayedLoading && !displayedTerms && !displayedPrivacyPolicy"
      >
        <h1
          class="accounts-form__bi-header cursor"
          @click="$router.push('/introduction/main')"
        >
          {{ title }}
        </h1>
        <div class="accounts-form_texts">
          <outlinedTextField
            placeholder="아이디(이메일)"
            @value="setEmail"
            class="accounts-form__outlined-text"
            type="text"
            case="email"
            @validate="validate"
          ></outlinedTextField>
          <p class="accounts-form__form-error" v-if="error.email">
            {{ error.email }}
          </p>
          <outlinedTextField
            placeholder="별명"
            @value="setName"
            class="accounts-form__outlined-text"
            type="text"
            maxlength="15"
          ></outlinedTextField>
          <p class="accounts-form__form-error" v-if="error.name">
            {{ error.name }}
          </p>
          <outlinedTextField
            placeholder="비밀번호"
            @value="setPassword"
            class="accounts-form__outlined-text"
            type="password"
            case="password"
            @validate="validate"
          ></outlinedTextField>
          <p class="accounts-form__form-error" v-if="error.password">
            {{ error.password }}
          </p>
          <outlinedTextField
            placeholder="비밀번호 확인"
            @value="setPasswordConfirmation"
            class="accounts-form__outlined-text"
            type="password"
            case="passwordConfirm"
            @validate="validate"
          ></outlinedTextField>
          <p
            class="accounts-form__form-error"
            v-if="error.passwordConfirmation"
          >
            {{ error.passwordConfirmation }}
          </p>
        </div>
        <div class="signup-form__agreements">
          <input
            type="checkbox"
            class="signup-form__agreements--checkbox"
            v-model="agreeEntire"
            @change="checkAgreeEntire"
          />
          <label
            class="signup-form__agreements--label"
            @click="checkAgreeEntire"
          >
            <b>전체 동의</b>
          </label>
        </div>
        <div class="signup-form__agreements">
          <input
            type="checkbox"
            class="signup-form__agreements--checkbox"
            v-model="agreeTerms"
          />
          <label class="signup-form__agreements--label" @click="checkAgreeTerms"
            >이용약관 동의</label
          >
          <a
            class="signup-form__agreements--link accounts-link__right cursor"
            @click="showTerms"
            >전문보기</a
          >
          <p class="accounts-form__form-error" v-if="error.terms">
            {{ error.terms }}
          </p>
        </div>
        <div class="signup-form__agreements">
          <input
            type="checkbox"
            class="signup-form__agreements--checkbox"
            v-model="agreePrivacyPolicy"
          />
          <label
            class="signup-form__agreements--label"
            @click="checkAgreePrivacyPolicy"
            >개인정보정책 동의</label
          >
          <a
            class="signup-form__agreements--link accounts-link__right cursor"
            @click="showPrivacyPolicy"
            >전문보기</a
          >
          <p class="accounts-form__form-error" v-if="error.privacyPolicy">
            {{ error.privacyPolicy }}
          </p>
        </div>
        <div class="signup-form__agreements">
          <input
            type="checkbox"
            class="signup-form__agreements--checkbox"
            v-model="agreeAdv"
          />
          <label class="signup-form__agreements--label" @click="checkAgreeAdv"
            >마케팅 정보 알림 수신 동의 (선택)</label
          >
        </div>
        <button
          class="signup-form__submit accounts-form__submit"
          @click="validateAll"
        >
          회원가입하기
        </button>
        <div class="signup__already">
          이미 큐픽 아이디가 있으신가요?
          <a href="/accounts/login" class="accounts-link__highlighted"
            >로그인</a
          >
        </div>
      </div>
      <!-- 약관 팝업 레이어 -->
      <termsPopup
        v-if="!displayedLoading && displayedTerms"
        title="이용약관"
        :contents="contentsTerms"
        @termsClose="hideTerms"
      />
      <termsPopup
        v-if="!displayedLoading && displayedPrivacyPolicy"
        title="개인정보보호정책"
        :contents="contentsPrivacyPolicy"
        @termsClose="hidePrivacyPolicy"
      />
    </div>
    <toastMsg ref="toast"></toastMsg>
  </div>
</template>

<script>
import authAPI from "@/api/accounts/auth.js";
import userAPI from "@/api/accounts/user.js";
import termsAPI from "@/api/accounts/terms.js";
import {chkValidationPassword} from "@/utils/user.js";
import {createNamespacedHelpers} from "vuex";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";
import "@/assets/scss/accounts/basis.scss";
import toastMsg from "@/components/accounts/toast-message.vue";
import termsPopup from "@/components/accounts/terms-popup.vue";

const ModuleAuth = createNamespacedHelpers("ModuleAuth");

export default {
  data() {
    return {
      title: "회원가입",
      email: "",
      name: "",
      password: "",
      passwordConfirmation: "",
      checkedEmail: false,
      checkedPassword: false,
      checkedPasswordConfirm: false,
      agreeEntire: false,
      agreeTerms: false,
      agreePrivacyPolicy: false,
      agreeAdv: false,
      checkedAgreeTerms: false,
      checkedAgreePrivacyPolicy: false,
      displayedLoading: true,
      displayedTerms: false,
      displayedPrivacyPolicy: false,
      contentsTerms: "",
      contentsPrivacyPolicy: "",
      loggedUserId: null,
      error: {
        email: "",
        name: "",
        password: "",
        passwordConfirmation: "",
        terms: "",
        privacyPolicy: ""
      }
    };
  },
  computed: {
    ...ModuleAuth.mapGetters(["getLoginData"])
  },
  methods: {
    //input 유효성 검사
    validate(obj) {
      const _case = obj.case;
      const _value = obj.value;

      if (_case === "email") {
        if (!_value) {
          this.error.email = "아이디를 입력해주세요.";
          this.checkedEmail = false;
        } else if (
          !_value.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)
        ) {
          this.error.email = "이메일 형식이 맞지 않습니다.";
          this.checkedEmail = false;
        } else {
          this.checkEmail(_value);
        }
      }

      if (_case === "password") {
        let resPassword = chkValidationPassword(obj.value);

        if (!resPassword.result) {
          this.error.password = resPassword.msg;
          this.checkedPassword = false;
        } else {
          this.error.password = "";
          this.checkedPassword = true;
        }
      }

      if (_case === "passwordConfirm") {
        if (!_value) {
          this.error.passwordConfirmation = "비밀번호 확인을 입력해주세요.";
          this.checkedPasswordConfirm = false;
        } else if (this.password !== _value) {
          this.error.passwordConfirmation = "비밀번호가 일치하지 않습니다.";
          this.checkedPasswordConfirm = false;
        } else {
          this.error.passwordConfirmation = "";
          this.checkedPasswordConfirm = true;
        }
      }
    },
    //회원가입하기 버튼 눌렀을때 모든 입력창 유효성 검사
    validateAll() {
      const inputs = [
        {case: "email", value: this.email},
        {case: "password", value: this.password},
        {case: "passwordConfirm", value: this.passwordConfirmation}
      ];

      inputs.forEach(item => {
        this.validate(item);
      });

      if (!this.agreeTerms) {
        this.error.terms = "이용약관 동의를 체크하세요.";
        this.checkedAgreeTerms = false;
      } else {
        this.error.terms = "";
        this.checkedAgreeTerms = true;
      }

      if (!this.agreePrivacyPolicy) {
        this.error.privacyPolicy = "개인정보정책 동의를 체크하세요.";
        this.checkedAgreePrivacyPolicy = false;
      } else {
        this.error.privacyPolicy = "";
        this.checkedAgreePrivacyPolicy = true;
      }

      //모든 필수값 유효성 검사 성공시
      if (
        this.checkedEmail &&
        this.checkedPassword &&
        this.checkedPasswordConfirm &&
        this.checkedAgreeTerms &&
        this.checkedAgreePrivacyPolicy
      )
        this.signup();
    },
    setEmail(v) {
      this.email = v;
    },
    setName(v) {
      this.name = v;
    },
    setPassword(v) {
      this.password = v;
    },
    setPasswordConfirmation(v) {
      this.passwordConfirmation = v;
    },
    showTerms() {
      this.displayedTerms = true;

      if (!this.contentsTerms.length) {
        this.displayedLoading = true;
        let payload = {};
        payload.termsId = "current";

        termsAPI.currentTermsOfUse(payload).then(res => {
          this.contentsTerms = res.data.contents["ko"];
          this.displayedLoading = false;
        });
      }
    },
    hideTerms() {
      this.displayedTerms = false;
    },
    showPrivacyPolicy() {
      this.displayedPrivacyPolicy = true;

      if (!this.contentsPrivacyPolicy.length) {
        this.displayedLoading = true;
        let payload = {};
        payload.termsId = "current";

        termsAPI.currentPrivacyPolicy(payload).then(res => {
          this.contentsPrivacyPolicy = res.data.contents["ko"];
          this.displayedLoading = false;
        });
      }
    },
    hidePrivacyPolicy() {
      this.displayedPrivacyPolicy = false;
    },
    signup() {
      let userApiRes;
      // 최초 회원가입
      userApiRes = userAPI.createRegular(
        this.email,
        this.name,
        this.password,
        this.agreeAdv
      );

      userApiRes.then(response => {
        switch (response.status) {
          case 201:
            this.signUpSuccess();
            break;

          default:
            this.signUpFail(response.data.errors);
            break;
        }
      });
    },
    //회원가입 실패 후처리
    signUpFail(errors) {
      errors.forEach(error => {
        if (error.code === "validation.unique" && error.target === "email") {
          this.error[error.target] = "이미 가입되어있는 아이디 입니다.";
          this.checkedEmail = false;
        } else if (
          error.code === "user.username.deleted" &&
          error.target === "email"
        ) {
          this.error[error.target] = error.userMsg;
          this.checkedEmail = false;
        } else {
          this.$refs.toast.show(error.userMsg);
        }
      });
    },
    //회원가입 성공 후처리
    signUpSuccess() {
      let payload = {};
      payload.username = this.email;
      payload.password = this.password;

      this.actLogin(payload).then(() => {
        // setAuthToken(responseToken.data.accessToken);
        this.$router.push(
          "/accounts/signup/certify/notice?email=" + this.email
        );
        // if (this.agreeAdv) {
        //   userAPI.setAdvAgree(signUpRes.data.id, true);
        // }
      });
    },
    //전체 동의하기 체크 이벤트
    checkAgreeEntire() {
      this.agreeEntire = !this.agreeEntire;
      this.agreeTerms = this.agreeEntire;
      this.agreePrivacyPolicy = this.agreeEntire;
      this.agreeAdv = this.agreeEntire;
    },
    //이용약관 동의 체크 이벤트
    checkAgreeTerms() {
      this.agreeTerms = !this.agreeTerms;

      if (this.agreeTerms && this.agreePrivacyPolicy && this.agreeAdv) {
        this.agreeEntire = true;
      }
      if (!this.agreeTerms) this.agreeEntire = false;
    },
    //개인정보 정책 동의 체크 이벤트
    checkAgreePrivacyPolicy() {
      this.agreePrivacyPolicy = !this.agreePrivacyPolicy;

      if (this.agreeTerms && this.agreePrivacyPolicy && this.agreeAdv) {
        this.agreeEntire = true;
      }
      if (!this.agreePrivacyPolicy) this.agreeEntire = false;
    },
    //마케팅 정보 알림 수신동의 체크 이벤트
    checkAgreeAdv() {
      this.agreeAdv = !this.agreeAdv;

      if (this.agreeTerms && this.agreePrivacyPolicy && this.agreeAdv) {
        this.agreeEntire = true;
      }
      if (!this.agreeAdv) this.agreeEntire = false;
    },
    checkEmail(email) {
      userAPI.checkEmail(email).then(response => {
        if (response.status === 204) {
          this.error.email = "";
          this.checkedEmail = true;
        } else {
          this.error.email = response.data.errors[0].msg;
          this.checkedEmail = false;
        }
      });
    },
    ...ModuleAuth.mapActions(["actLogin"])
  },
  components: {
    termsPopup,
    toastMsg,
    outlinedTextField: () =>
      import("@/components/accounts/outlined-text-field.vue")
  },
  mounted() {
    if (this.$route.query.callback_url) {
      sessionStorage.setItem(
        "accounts_signup_callback_url",
        this.$route.query.callback_url
      );
    }

    if (this.$route.query.callback_name) {
      sessionStorage.setItem(
        "accounts_signup_callback_name",
        this.$route.query.callback_name
      );
    }
    this.displayedLoading = false;
    authAPI.logged().then(res => {
      if (res.status == 200) {
        alert("이미 회원으로 가입되어 있습니다");
        this.$router.replace("/accounts/login");
      } else {
        this.title = "회원가입";
      }
    });
  }
};
</script>
<style>
.not-show-border {
  border: none;
}
.signup__already {
  margin-top: 16px;
  margin-bottom: 56px;
  text-align: center;
  color: #8d8d8d;
  font-size: 14px;
}
.signup__already a {
  margin-left: 8px;
}
.signup-form__agreements {
  padding-left: 6px;
  margin-bottom: 4px;
}
.signup-form__agreements:nth-of-type(2) {
  margin-top: 8px;
  margin-bottom: 16px;
  border-bottom: 1px solid #f1f1f1;
}
.signup-form__agreements:last-of-type {
  margin-bottom: 12px;
}
.signup-form__agreements--label {
  margin-left: 4px;
  margin-bottom: 8px;
}
.signup-form__agreements--checkbox {
  visibility: hidden;
  margin-right: -22px;
}
.signup-form__agreements--checkbox ~ .signup-form__agreements--label::before {
  position: relative;
  top: 5px;
  display: inline-block;
  content: "";
  width: 24px;
  height: 24px;
  margin-right: 8px;
  background-repeat: no-repeat;
  background-position: 0 50%;
  background-size: 24px auto;
  background-image: url("~@/assets/images/common/bg_checkbox.svg");
}
.signup-form__agreements--checkbox:checked
  ~ .signup-form__agreements--label::before {
  background-image: url("~@/assets/images/common/bg_checkbox_active.svg");
}
.signup-form__agreements--label {
  position: relative;
  top: -3px;
  display: inline-block;
  line-height: 30px;
  color: #2c2c2c;
  font-size: 16px;
}
.signup-form__agreements--link {
  float: right;
}
.signup-form__agreements--link ~ .accounts-form__form-error {
  margin-top: -2px;
  margin-left: -6px;
}
.signup-form__submit::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 8px;
  background-image: url("~@/assets/images/accounts/icon-ok.svg");
  background-size: 24px 24px;
  background-repeat: no-repeat;
  background-position: center center;
  vertical-align: -6px;
}
.shadow {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
  animation: fadein 1s;
}
.signup-terms,
.signup-privacy {
  position: relative;
}
.signup-terms__header,
.signup-privacy__header {
  display: block;
  position: absolute;
  height: 20px;
  padding-top: 0;
  padding-bottom: 16px;
  border-bottom: 1px solid #f1f1f1;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
}
.signup-terms__bi,
.signup-privacy__bi {
  display: block;
  position: absolute;
  width: 50px;
  height: 16px;
  background-image: url("~@/assets/images/accounts/bi-typo.svg");
  background-size: 50px 16px;
  background-repeat: no-repeat;
  background-position: left center;
  font-size: 0;
}
.signup-terms__contents,
.signup-privacy__contents {
  height: 702px;
  color: #484948;
  font-size: 14px;
  font-weight: normal;
}
.signup-privacy__contents__inner,
.signup-terms__contents__inner {
  width: calc(100% - 20px);
}
.signup-terms__close,
.signup-privacy__close {
  position: absolute;
  font-size: 0;
}
.signup-terms__close::after,
.signup-privacy__close::after {
  content: "×";
  font-size: 24px;
}
@media screen and (min-width: 720px) {
  .signup-terms,
  .signup-privacy {
    position: relative;
    padding: 170px 48px 48px 48px;
  }
  .signup-terms__header,
  .signup-privacy__header {
    top: 118px;
    left: 48px;
    right: 48px;
  }
  .signup-terms__bi,
  .signup-privacy__bi {
    top: 70px;
    left: 48px;
  }
  .signup-terms__close,
  .signup-privacy__close {
    top: 55px;
    right: 48px;
  }
}
@media screen and (max-width: 719px) {
  .signup-terms,
  .signup-privacy {
    position: relative;
    padding: 130px 0 16px 0;
  }
  .signup-terms__header,
  .signup-privacy__header {
    top: 80px;
  }
  .signup-terms__bi,
  .signup-privacy__bi {
    top: 32px;
  }
  .signup-terms__close,
  .signup-privacy__close {
    top: 22px;
    right: 0px;
  }
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
